import React, { useEffect, useState } from "react";
import { MuiOtpInputStyled, MyIdWrapper } from "./Otp.s";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import NewOrder from "components";
import { useNavigate, useLocation } from "react-router-dom";
import { useOtpMutation } from "store/services/otp";
import { useApplicationCreatedMutation } from "store/services/application";
import { useTranslation } from "react-i18next";
import { BorderLinearProgress, WrapperTop } from "components/card-information/index.s";

type Props = {};
const Otp = ({}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const application_id = queryParams.get("");
  const otp_id = queryParams.get("otp_id");
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [countdownActive, setCountdownActive] = useState(true);
  const [OtpVerify, { isLoading, isSuccess, isError, data }] = useOtpMutation();

  const userJson = JSON.parse(
    window.localStorage.getItem("cardInformationData") || "{}"
  );

  useEffect(() => {
    if (data?.result) {
      navigate(`/identification/${application_id}`);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (countdownActive && seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (seconds === 0) {
      setOtp("");
      setCountdownActive(false); // Stop countdown
    }
  }, [seconds, countdownActive]);

  const handleChange = (newValue: string) => {
    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");
    setOtp(numericValue);
  };

  const [
    createApplication,
    {
      isLoading: ApplicationLoading,
      isSuccess: ApplicationSuccess,
      isError: ApplicationError,
      data: ApplicationData,
    },
  ] = useApplicationCreatedMutation();

  useEffect(() => {
    if (ApplicationData?.result?.application_id) {
      navigate(
        `/sms/application_id?=${ApplicationData.result.application_id}&otp_id=${ApplicationData.result.otp_id}`
      );
    }
  }, [ApplicationData, navigate]);

  const handleResetOTP = async () => {
    try {
      const result = await createApplication({
        requestData: userJson,
      }).unwrap();
    } catch (error) {
      console.error("Failed to create application:", error);
    }
    setOtp("");
    setSeconds(60);
    setCountdownActive(true);
  };

  const handleCancel = () => {
    window.localStorage.removeItem("card_type");
    navigate("/");
  };

  const handleOtp = async () => {
    try {
      await OtpVerify({
        requestData: {
          otp,
          id: Number(otp_id),
        },
        application_id: application_id ?? "", // Ensure application_id is not null
      }).unwrap();
    } catch (error) {
      console.error("Failed to verify OTP", error);
    }
  };

  return (
    <>
      <NewOrder>
        <MyIdWrapper>
          <h2>{t("Otp.0")}</h2>
          <h5>{t("Otp.7")}</h5>
          <MuiOtpInputStyled
            length={6}
            value={otp}
            onChange={handleChange}
            TextFieldsProps={{
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
            }}
          />
          {isError && <span>{t("Otp.1")}</span>}
          {!countdownActive && (
            <p onClick={handleResetOTP}>
              <RefreshIcon />
              {t("Otp.2")}
            </p>
          )}
          {countdownActive && (
            <p>
              {t("Otp.3")} {seconds} {t("Otp.4")}
            </p>
          )}
          <Button
            variant="contained"
            color="primary"
            sx={{background:'rgb(254 150 50)' , ':hover' : {background:'rgb(254 150 50)'}}}
            onClick={handleOtp}
            disabled={isLoading || otp.length !== 6}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            style={{ position: "relative", display: "flex", alignItems: "center" }}
          >
            {isLoading ? `${ t("Otp.5")}...` : t("Otp.5")}
          </Button>
        </MyIdWrapper>
      </NewOrder>
    </>
  );
};

export default Otp;
