import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import MyIdIframe from "components/my-id";
import CardInformation from "components/card-information";
import Otp from "components/otp";
import Passport from "components/my-id/passport-verfiy/passport";
import { Provider } from "react-redux";
import { store } from "store/store";
import ErrorLogice from "components/error-logice";
import OtpBank1 from "components/otp-bank-1";
import OtpBank2 from "components/otp-bank-2";
import ErrorServer from "components/error-server";
import LazyLoading from "components/lazy-loading";
import Finish from "components/finish/finish";

function App() {
  const [props, setProps] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.props) {
        setProps(event.data.props);
      }
    };

    window.addEventListener("message", handleMessage);
    window.parent.postMessage("requestProps", "*");

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (props.public_key) {
      const login = async () => {
        setIsError(false);
        setTimeoutError(false);
        const controller = new AbortController();
        const timeout = setTimeout(() => {
          controller.abort();
          setTimeoutError(true);
          setIsLoading(false);
        }, 5000);

        try {
          const response = await fetch(
            "https://api.allgoodnasiya.uz/auth/public-login",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ public_key: props.public_key }),
              signal: controller.signal,
            }
          );

          clearTimeout(timeout);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          localStorage.setItem("accessToken", result.result.access_token);
        } catch (error) {
          console.error("Failed to create Login:", error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };

      login();
    } else {
      setTimeout(() => setIsLoading(false), 5000);
    }
  }, [props.public_key]);

  if (isLoading) return <LazyLoading />;
  if (timeoutError) return <ErrorServer />;
  if (isError || !props.public_key) return <ErrorLogice />;

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/card-info" />} />
          <Route path="/card-info" element={<CardInformation />} />
          <Route path="/sms/:application_id" element={<Otp />} />
          <Route path="/identification/:application_id" element={<Passport />} />
          <Route path="/identification-face-id/" element={<MyIdIframe broker={props.broker} />} />
          <Route path="/otp-bank1/:application_id" element={<OtpBank1 />} />
          <Route path="/otp-bank2/:application_id" element={<OtpBank2 />} />
          <Route path="/finish/:application_id" element={<Finish />} />
        </Routes>
      </Router>
    </Provider>
  );
}


export default App;
