import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {
  BoxInput,
  BoxInputCard,
  BoxValidate,
  Wrapper,
  FormWrapper,
  BorderLinearProgress,
  WrapperTop,
} from "./index.s";
import NewOrder from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { useApplicationCreatedMutation } from "store/services/application";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const phoneRegExp = /^\d{2}-\d{3}-\d{2}-\d{2}$/;

const CardInformation = () => {
  const navigate = useNavigate();
  const [ErrorCard, setErrorCard] = useState<number>();
  const [ErrorCardStatus, setErrorCardStatus] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const phoneNumberRef = useRef(null);
  const relativeNumberRef = useRef(null);
  const cardNumberRef = useRef(null);
  const expiryDateRef = useRef(null);
  const { t } = useTranslation();

  const SignupSchema = Yup.object().shape({
    close_phone: Yup.string()
      .matches(phoneRegExp, t("Error.4"))
      .required(t("Error.3")),
    owner_phone: Yup.string()
      .matches(phoneRegExp, t("Error.4"))
      .required(t("Error.3")),
    card: Yup.string()
      .matches(/^\d{4}\s\d{4}\s\d{4}\s\d{4}$/, t("Error.5"))
      .required(t("Error.3")),
    card_expiry: Yup.string()
      .transform((value) => value.replace(/\//g, ""))
      .matches(/^\d{4}$/, t("Error.6"))
      .required(t("Error.3")),
  });

  const [createApplication, { isLoading, isSuccess, isError, data }] =
    useApplicationCreatedMutation();

  if (data?.result && data?.result?.application?.id) {
    navigate(
      `/sms/application_id?=${data?.result?.application.id}&otp_id=${data?.result?.otp_id}`
    );
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitting(true);
      await SignupSchema.validate(values, { abortEarly: false });
      const formattedValues = {
        ...values,
        owner_phone: `998${values.owner_phone.replace(/-/g, "")}`,
        close_phone: `998${values.close_phone.replace(/-/g, "")}`,
        add_phone: values.add_phone
          ? `998${values.add_phone.replace(/-/g, "")}`
          : "",
        card: values.card.replace(/\s/g, ""),
        card_expiry: values.card_expiry.replace("/", ""),
      };

      window.localStorage.setItem(
        "cardInformationData",
        JSON.stringify(formattedValues)
      );

      const response = await axios.get(
        `https://api.allgoodnasiya.uz/dictionaries/bincodes?search=${formattedValues.card.slice(
          0,
          4
        )}`
      );
      setErrorCard(response.data.result.length);

      if (response.data.result.length === 0) {
        setErrorCardStatus(true);
      } else {
        window.localStorage.setItem(
          "card_type",
          response?.data?.result[0].card_type
        );
        setErrorCardStatus(false);
      }
      if (response.data.result.length !== 0) {
        await createApplication({
          requestData: formattedValues,
        }).unwrap();
      }
    } catch (error) {
      console.error("Failed to validate or create application:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NewOrder>
      <Wrapper>
        <WrapperTop>
          <h3>{t("Новая заявка")}</h3>
          <h5>{t("Введите свои данные")}</h5>
          <BorderLinearProgress variant="determinate" value={33} />
        </WrapperTop>
        <Formik
          initialValues={{
            add_phone: "",
            owner_phone: "",
            close_phone: "",
            card: "",
            card_expiry: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, isValid }) => (
            <Form>
              <FormWrapper>
                <BoxValidate>
                  <label>{t("CardInformation.0")}</label>
                  <BoxInput>
                    <span>+998</span>
                    <Field name="owner_phone">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="99-999-99-99"
                          maskChar={null}
                          onChange={(e) =>
                            setFieldValue("owner_phone", e.target.value)
                          }
                          ref={phoneNumberRef}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              placeholder="__-___-__-__"
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </BoxInput>
                  <ErrorMessage name="owner_phone" component="p" />
                </BoxValidate>

                <BoxValidate>
                  <label>{t("CardInformation.1")}</label>
                  <BoxInput>
                    <span>+998</span>
                    <Field name="close_phone">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="99-999-99-99"
                          maskChar={null}
                          onChange={(e) =>
                            setFieldValue("close_phone", e.target.value)
                          }
                          ref={relativeNumberRef}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              placeholder="__-___-__-__"
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </BoxInput>
                  <ErrorMessage name="close_phone" component="p" />
                </BoxValidate>

                <BoxValidate>
                  <label>{t("CardInformation.3")}</label>
                  <BoxInputCard>
                    <Field name="card">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="9999 9999 9999 9999"
                          maskChar={null}
                          onChange={(e) =>
                            setFieldValue("card", e.target.value)
                          }
                          ref={cardNumberRef}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              placeholder="0000 0000 0000 0000"
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </BoxInputCard>
                  <ErrorMessage name="card" component="p" />
                  {ErrorCardStatus && ErrorCard === 0 && (
                    <p>{t("CardInformation.6")}</p>
                  )}
                </BoxValidate>

                <BoxValidate>
                  <label>{t("CardInformation.4")}</label>
                  <BoxInputCard>
                    <Field name="card_expiry">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask="99/99"
                          maskChar={null}
                          onChange={(e) =>
                            setFieldValue("card_expiry", e.target.value)
                          }
                          ref={expiryDateRef}
                        >
                          {(inputProps) => (
                            <input
                              {...inputProps}
                              type="text"
                              placeholder="MM/YY"
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </BoxInputCard>
                  <ErrorMessage name="card_expiry" component="p" />
                </BoxValidate>
              </FormWrapper>
              <Button
                type="submit"
                variant="contained"
                sx={{background:'rgb(254 150 50)' , ':hover' : {background:'rgb(254 150 50)'}}}
                color="primary"
                disabled={!isValid || isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? `${t("CardInformation.5")}...` : t("CardInformation.5")}
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </NewOrder>
  );
};

export default CardInformation;
